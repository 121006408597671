@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Work Sans';
  src: url('../../public/fonts/WorkSans-VariableFont.ttf') format('truetype');
  font-display: block;
}

body { opacity: 1 !important; }

/* Remove default image  */
img {
  overflow: visible;
}

a {
  color: #06b2b6;
}

a:visited {
  color: #155772;
}

@layer base {
  .type-body-bold {
    @apply type-body weight-600;
  }

  .type-title {
    @apply font-WorkSans text-[60px] sm:text-[70px] md:text-[85px] lg:text-[110px] xl:text-[140px] 2xl:text-[170px] leading-none weight-300 tracking-tighter;
  }

  .type-heading {
    @apply font-WorkSans text-[36px] sm:text-[36px] md:text-[46px] lg:text-[50px] xl:text-[60px] 2xl:text-[80px] leading-none tracking-tight weight-300;
  }

  .type-heading-alt {
    @apply font-WorkSans text-[32px] sm:text-[32px] md:text-[36px] lg:text-[38px] xl:text-[46px] 2xl:text-[56px] leading-none tracking-tight weight-300;
  }

  .type-heading-small {
    @apply type-heading-alt;
  }

  .type-subheading {
    @apply font-WorkSans text-[26px] sm:text-[26px] md:text-[30px] lg:text-[36px] xl:text-[42px] 2xl:text-[50px] leading-none tracking-tight weight-400;
  }

  .type-subheading-small {
    @apply font-WorkSans text-[24px] sm:text-[24px] md:text-[24px] lg:text-[28px] xl:text-[32px] 2xl:text-[40px] leading-none tracking-tight weight-400;
  }

  .type-subheading-xs {
    @apply font-WorkSans text-[20px] sm:text-[20px] md:text-[22px] lg:text-[26px] xl:text-[28px] 2xl:text-[32px] leading-none tracking-tight weight-400;
  }

  .type-body {
    @apply font-WorkSans text-[18px] sm:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[28px] weight-400 leading-[1.25] tracking-tight;
  }

  .type-body-small {
    @apply font-WorkSans text-[16px] sm:text-[16px] md:text-[18px] lg:text-[22px] xl:text-[24px] weight-400 leading-[1.25] tracking-tight;
  }

  .type-body-base {
    @apply font-WorkSans text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] weight-400 leading-normal tracking-normal;
  }




  .type-subscript {
    @apply font-WorkSans text-sm leading-tight tracking-tight;
    font-variation-settings: 'wght' 300;
  }
}

@layer utilities {
  .weight-900 {
    font-variation-settings: 'wght' 900;
  }

  .weight-700 {
    font-variation-settings: 'wght' 700;
  }

  .weight-600 {
    font-variation-settings: 'wght' 600;
  }

  .weight-500 {
    font-variation-settings: 'wght' 500;
  }

  .weight-400 {
    font-variation-settings: 'wght' 400;
  }

  .weight-300 {
    font-variation-settings: 'wght' 300;
  }

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .no-shift {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    transition: all 0.25s;
    position: relative;
  }

  .no-shift:hover {
    @apply weight-600;
  }

  .no-shift::after {
    content: attr(data-i18n);
    content: attr(data-i18n) / '';
    height: 0;
    position: relative;
    left: -100%;
    text-align: center;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    @apply weight-600;
    font-size: inherit;
  }

  .wrapper {
    @apply container mx-auto px-2 md:px-6 lg:px-8;
  }

  .content-wrapper-x {
    @apply px-6 md:px-8 lg:px-16;
  }
  
  .content-wrapper {
    @apply p-6 md:p-8 lg:p-16;
  }
  strong, b {
    @apply weight-500;
  }

  .allow-html.allow-html {
    @apply type-body-base;
  }

  .allow-html.allow-html h2 { 
    @apply weight-500 type-heading mb-4;
  }

  .allow-html.allow-html p { 
    @apply text-inherit;
  }
  
  .allow-html.allow-html strong, .allow-html.allow-html b { 
    @apply text-inherit weight-600;
  }

  .allow-html.allow-html a { 
    @apply text-inherit weight-600 text-xumoBerry underline decoration-transparent hover:decoration-xumoBerry transition-colors;
  }
  
  .allow-html.allow-html blockquote {
    @apply pl-4 border-l-2 border-xumoBerry text-md lg:text-xl;
  }
  
  .allow-html.allow-html blockquote em {
    @apply type-body;
  }
  
  .allow-html.allow-html ol {
    @apply list-decimal pl-4 ml-1;
  }
  
  .allow-html.allow-html ul {
    @apply list-disc pl-4 ml-1;
  }
  
  .allow-html.allow-html li,  .allow-html.allow-html ol,  .allow-html.allow-html ul {
    @apply text-inherit;
  }

  .allow-html.allow-html p:not(:last-child), .allow-html.allow-html blockquote:not(:last-child), .allow-html.allow-html ul:not(:last-child) {
    @apply mb-4;
  }
  
  .allow-html.allow-html li:not(:last-child) {
    @apply mb-1;
  }

  .allow-html.allow-html.allow-html  p + ul, .allow-html.allow-html.allow-html  p + ol { 
    @apply -mt-2;
  }
  
}

body { 

}

.floating-border::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  display: block;
  border: 2px solid #2AAC00;
  border-radius: 12px;
  opacity: 0;
  transition: all .3s ease 0s;
  transition-property: opacity,border-color;
}

.floating-border:hover::before {
    opacity: 1;
}

h1 sup {
  font-size: 0.25em;
  top: -2em;
}


.xumo-globe .marker {
    background-size: 512px 256px;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    border: 0px solid #fff;
    transition: border-width 0.2s;
    box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.2);
}

.xumo-globe .markerContainer {
    z-index: 1;
    transition: opacity 0.2s;
}

.xumo-globe .markerContainer.occluded {
    opacity: 0;
}

.xumo-globe .markerContainer:hover {
    z-index: 50000000 !important;
}

.xumo-globe .markerContainer:hover .marker {
    border-width: 10px;
    transition: border-width 0.2s;
}

.xumo-globe .markerContainer:hover .markerRipple {
    display: none;
}

.xumo-globe .markerContainer:hover .tooltip {
    z-index: 50000000 !important;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
}

.xumo-globe .markerContainer:hover .tooltip .fadein {
    opacity: 1;
    transform: translateY(0);
}

.xumo-globe .tooltip {
    position: absolute;
    top: -25px;
    visibility: hidden;
    opacity: 0;
    z-index: 900;
    transform: translateX(30px);
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    min-width: 12rem;
    pointer-events: none;
}

.xumo-globe .tooltip:before {
    content: '';
    position: absolute;
    top: 15px;
    left: -19px;
    width: 0;
    height: 0;
    border-color: transparent #fff transparent transparent;
    border-width: 10px;
    border-style: solid;
}

.xumo-globe .tooltip h3 {
    margin: 0;
    font-weight: normal;
    font-size: 1.75rem;
}

.xumo-globe .tooltip .number {
    font-size: 0.75rem;
    text-transform: uppercase;
}

.xumo-globe .tooltip .icons {
    padding: 1rem 0;
    display: grid;
    row-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.xumo-globe .tooltip .icons .icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #ddd;
}

.xumo-globe .tooltip .btn {
    background-color: #155772;
    color: white;
    font-size: 0.65rem;
    border-radius: 4px;
    padding: 0.5rem;
    display: block;
    text-decoration: none;
}

.xumo-globe .markerRipple {
    z-index: 0;
    animation: ripple 2s infinite ease-out;
    pointer-events: none;
}

.xumo-globe .center {
  pointer-events: none;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale3d(0.75, 0.75, 1);
    }
    to {
        opacity: 0;
        transform: translate(-50%, -50%) scale3d(1.5, 1.5, 1);
    }
}


.cog .cog-rotate {
  animation-name: cog-rotation;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

.cog:nth-child(2n) .cog-rotate {
  animation-direction: reverse;
}

@keyframes cog-rotation {
  from { 
      transform: rotate(0deg);
  }

  to { 
      transform: rotate(360deg);
  }
}

.hide-breaks br { @apply max-md:hidden }

 @media (min-width: 768px) { 
  
  .nav-link { position: relative }
  .nav-link > span:after {
   content: ' '; 
   
   transform: scaleX(0); 
   height: 2px; 
   display: block;
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   transition: transform 0.2s;

   @apply bg-xumoTeal;
  }

  .nav-link:hover > span:after {
    transform: scaleX(1); 
  }

  .nav-active > span:after {
    transform: scaleX(1) !important; 
  }
}



.carousel-progress-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  transform-origin: left;
  background: #BB2E76;
  background-clip: border-box;
}

@keyframes grow {
  0% {
      transform: scaleX(0);
  }
  100% {
      transform: scaleX(1);
  }
}

.button-test {
  background-clip: content-box;
  border: 1px solid transparent;
}



.btn-row.btn-row.btn-row.btn-row:last-child {
  margin-top: 0 !important;
}

.btn-row.btn-row.btn-row.btn-row:nth-child(1 of .btn-row) {
  margin-top: 1rem !important;
}